import { useBlockProps, RichText } from '@wordpress/block-editor';

/**
 * The save function defines the way in which the different attributes should
 * be combined into the final markup, which is then serialized by the block
 * editor into `post_content`.
 *
 * @see https://developer.wordpress.org/block-editor/developers/block-api/block-edit-save/#save
 *
 * @return {WPElement} Element to render.
 */
export default function save({className, attributes}) {
	return (
		<div { ...useBlockProps.save() } className="peugeauto-guestbook-entry">
			<div className="peugeauto-guestbook-entry__left-beam"></div>
			<div className="peugeauto-guestbook-entry__main">
				<h3 className="peugeauto-guestbook-entry__title">{attributes.header}</h3>
				<p className="peugeauto-guestbook-entry__text">
					<RichText.Content value={attributes.text} />
				</p>
				<span className="peugeauto-guestbook-entry__attribution">{attributes.attribution}</span>
			</div>
		</div>
	);
}
