import { useBlockProps } from '@wordpress/block-editor';
import { TextControl } from '@wordpress/components';
import { RichText } from '@wordpress/block-editor';
import './editor.scss';

/**
 * The edit function describes the structure of your block in the context of the
 * editor. This represents what the editor will render when the block is used.
 *
 * @see https://developer.wordpress.org/block-editor/developers/block-api/block-edit-save/#edit
 *
 * @return {WPElement} Element to render.
 */
export default function Edit({attributes, setAttributes}) {
	return (
		<div { ...useBlockProps() } className="peugeauto-guestbook-entry">
			<div className="peugeauto-guestbook-entry__left-beam"></div>
			<div className="peugeauto-guestbook-entry__main">
				<TextControl 
					hideLabelFromVision={false}
					value={attributes.header}
					placeholder="Voeg hier de titel van de gastenboek notering in"
					onChange={ v => {setAttributes({header: v})}}
				/>
				<RichText 
					value={attributes.text}
					onChange={v => setAttributes({text: v})}
					placeholder="Type hier de hoofdtekst van de gastenboek notering"
					allowedFormats={['core/bold', 'core/italic']}
				/>
				<TextControl 
					hideLabelFromVision={false}
					value={attributes.attribution}
					placeholder="Voeg hier de auteur van de gastenboek notering in"
					onChange={ v => {setAttributes({attribution: v})}}
				/>
			</div>
		</div>
	);
}
