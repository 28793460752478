import { registerBlockType } from '@wordpress/blocks';
import './style.scss';

import Edit from './edit';
import save from './save';

/**
 * Every block starts by registering a new block type definition.
 *
 * @see https://developer.wordpress.org/block-editor/reference-guides/block-api/block-registration/
 */
registerBlockType( 'peugeauto/guestbook-entry', {
	title: 'Gastenboek notering',
	description: 'Laat een notering in het gastenboek op een Peugeauto manier zien',
	icon: 'format-aside',
	category: 'layout',
	attributes: {
		header: {
			type: 'string',
			source: 'text',
			selector: 'h3.peugeauto-guestbook-entry__title',
			default: ''
		},
		text: {
			type: 'string',
			source: 'html',
			selector: 'p.peugeauto-guestbook-entry__text',
			default: ''
		},
		attribution: {
			type: 'string',
			source: 'text',
			selector: 'span.peugeauto-guestbook-entry__attribution',
			default: ''
		}
	},
	/**
	 * @see ./edit.js
	 */
	edit: Edit,

	/**
	 * @see ./save.js
	 */
	save,
} );
